<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Obrázky - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.iae_id"
        :columns="columns"
        :loading="loading"
        :dataSource="images"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="iae_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/image/detail/'+value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span slot="products_count" slot-scope="text">
          <i class="fas fa-sitemap"></i>&nbsp;&nbsp;
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </span>
        <span slot="manufacturers_count" slot-scope="text">
          <i class="fas fa-industry"></i>&nbsp;&nbsp;
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </span>
        <span slot="blogs_count" slot-scope="text">
          <i class="fas fa-blog"></i>&nbsp;&nbsp;
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </span>
        <template slot="categories" slot-scope="record">
          <div :style="{'margin-top': cIndex === 0 ? '0' : '5px'}" :key="category.icy_id + 'icd'" v-for="(category, cIndex) in record">
            <router-link
              class="utils__link--blue"
              :to="'/image/category/detail/'+category.icy_id"
            >
                {{ category.icy_name }}
            </router-link>
          </div>
        </template>
        <span slot="categories_thumb_count" slot-scope="text">
          <i class="fas fa-list-ul"></i>&nbsp;&nbsp;
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </span>
        <span
          slot="image"
          slot-scope="record"
          :class="$style.thumbnail"
        >
          <img class="img-size-10" v-if="!image.errors.includes(record.iae_id)" :src="$uploadImagePath + record.iae_path + getImageSize(record, 10) + '.' + record.iae_format" @error="image.errors.push(record.iae_id)">
          <a-icon v-else theme="twoTone" twoToneColor="#ccc" type="frown" />
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/image/detail/'+record.iae_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item :key="$uploadImagePath + record.iae_path + getImageSize(record, 1) + '.' + record.iae_format">
                <a :href="$uploadImagePath + record.iae_path + getImageSize(record, 1) + '.' + record.iae_format" target='_blank'>Originál</a>
              </a-menu-item>
              <a-menu-item v-for="(imageSize, index) in record.image_sizes" :key="'menu'+index">
                <a :href="$uploadImagePath + record.iae_path + (imageSize.identifier) + '.' + record.iae_format" target='_blank'>{{ imageSize.width + ' x ' + imageSize.height }}</a>
              </a-menu-item>
            </a-menu>
            <a-button
              size="small"
              class="mr-1">
              <a-icon type="download" /> Stáhnout
            </a-button>
          </a-dropdown>
          <a-popconfirm @confirm="confirmDelete([record.iae_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.iae_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ images.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'

export default {
  mixins: [imageSizeMixin],
  components: { ActionTools },
  data: function () {
    return {
      image: {
        errors: [],
      },
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/image/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'iae_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.iae_id - b.iae_id,
          scopedSlots: {
            customRender: 'iae_id',
          },
        },
        {
          title: 'Obrázek',
          dataIndex: '',
          scopedSlots: {
            customRender: 'image',
          },
        },
        {
          title: 'Název',
          dataIndex: 'iae_name',
          sorter: (a, b) => {
            return a.iae_name.localeCompare(b.iae_name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Formát',
          dataIndex: 'iae_format',
          sorter: (a, b) => {
            return a.iae_format.localeCompare(b.iae_format)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Kategorie',
          dataIndex: 'image_categories',
          scopedSlots: {
            customRender: 'categories',
          },
        },
        {
          title: 'Produktů',
          dataIndex: 'products_count',
          sorter: (a, b) => a.products_count - b.products_count,
          scopedSlots: {
            customRender: 'products_count',
          },
        },
        {
          title: 'Výrobců',
          dataIndex: 'manufacturers_count',
          sorter: (a, b) => a.manufacturers_count - b.manufacturers_count,
          scopedSlots: {
            customRender: 'manufacturers_count',
          },
        },
        {
          title: 'Blogů',
          dataIndex: 'blogs_count',
          sorter: (a, b) => a.blogs_count - b.blogs_count,
          scopedSlots: {
            customRender: 'blogs_count',
          },
        },
        {
          title: 'Produktových kategorií',
          dataIndex: 'categories_thumb_count',
          sorter: (a, b) => a.categories_thumb_count - b.categories_thumb_count,
          scopedSlots: {
            customRender: 'categories_thumb_count',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    imageSizes: function () {
      return this.$store.getters['image/getSizes']
    },
    images: function () {
      return this.$store.getters['image/getListFilteredItems'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.images.map(item => item.iae_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('image/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(images) {
      this.loading = true
      this.$store.dispatch('image/delete', images)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = images.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !images.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.images.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
